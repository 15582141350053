import * as React from 'react';
import Layout from '../../components/Layout';
import ClientsView from '../../modules/clients';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import ContactForm from '../../modules/contact/ContactForm';

const Clients = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Clients are our strong point. Checkout our latest partners for growth and the projects." />
        <title>Some of our projects and work - Zegates International</title>
      </Helmet>
      <ContactForm />
      <ClientsView />
      <Footer />
    </Layout>
  );
};

export default Clients;
