import React from "react";
import { Section, SectionTitle, ServiceHeader, MoreInfo, SectionImage, SectionDescription, Row, Tags } from "./Styled";
import MoreInfoIcon from '../../images/svgs/more-info.svg';

const Clients = () => {

  return (
    <>
      <ServiceHeader>
        <h1 className="font-bold">
          Join our<br />
          <span>Experts Partner</span> network to <br />
          Thrive your <span>idea to reality</span><br />
        </h1>
      </ServiceHeader>
      <Row>
      <Section>
          <SectionImage>
            <div>
              <img src='/images/projects/netlise-pos.jpg' />
            </div>
          </SectionImage>
          <Tags>
            <div>Enterprise</div>
            <div>SaaS</div>
          </Tags>
          <SectionDescription>
            <h2 className="text-2xl my-4">Netlise Platform</h2>
            <p className="my-4">
              Process orders with online and offline capabilities. Access you Point of Sale operations from your machine, iPad and touch terminal.
              Syncs all the restaurant operations and back of the house ops in one platform.
            </p>
            <p>
              View reports and activities from dashboard and get insites on productivity trends.
            </p>
            <MoreInfo href="/projects/netlise" color="blue">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </SectionDescription>
        </Section>
        <Section>
          <SectionImage>
            <div>
              <img src='/images/projects/inspectafy-project.jpg' />
            </div>
          </SectionImage>
          <Tags>
            <div>SaaS</div>
          </Tags>
          <SectionDescription>
            <h2 className="text-2xl my-4">Inspectafy</h2>
            <p className="my-4">
              Inspectafy is a platform which performs valuation of assets and properties for companies.
            </p>
            <p className="my-4">
              People can sign up with the platform and create company profiles. Also the platform allows to use different types of templates to produce reports on inspections.
            </p>
            {/* <MoreInfo color="blue" href="/projects/netlise">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo> */}
          </SectionDescription>
        </Section>
      </Row>

      <Row>
        <Section>
          <SectionImage>
            <div>
              <img src='/images/projects/wergo.jpg' />
            </div>
          </SectionImage>
          <Tags>
            <div>Mobile</div>
          </Tags>
          <SectionDescription>
            <h2 className="text-2xl my-4">Wergo</h2>
            <p className="my-4">
              Make new friends and organize your current friend groups using join my bubble by wergo.
            </p>
            <p className="my-4">
              Find nearest activities and events to participate with communities.
            </p>
            <MoreInfo href="/projects/wergo" color="blue">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </SectionDescription>
        </Section>
        <Section>
          <SectionImage>
            <div>
              <img src='/images/projects/tvilu-project-zegates.jpg' />
            </div>
          </SectionImage>
          <Tags>
            <div>Enterprise</div>
            <div>Mobile</div>
            <div>SaaS</div>
          </Tags>
          <SectionDescription>
            <h2 className="text-2xl my-4">Tvilu - Salon management platform</h2>
            <p className="my-4">
              Tvilu is a platform which significantly improve the user experience with salons and beauty parlors.
              With Tvilu users can make appointments and salons can approve or reschedule their appointments.
            </p>
            {/* <MoreInfo href="" color="blue">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo> */}
          </SectionDescription>
        </Section>
      </Row>
      <Row>
        <Section>
          <SectionImage>
            <div>
              <img src='/images/projects/refrl-app.jpg' />
            </div>
          </SectionImage>
          <Tags>
            <div>Mobile</div>
          </Tags>
          <SectionDescription>
            <h2 className="text-2xl my-4">Refrl</h2>
            <p className="my-4">
              Earn by generating leads! Get coupons and rewards by inviting people to shops and services.
            </p>
            <p className="my-4">
              Get rewards from Amazon, Alibaba and Wallet prices to purchase anything from the platform.
            </p>
            {/* <MoreInfo href="#" color="blue">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo> */}
          </SectionDescription>
        </Section>
        <Section>
          <SectionImage>
            <div>
              <img src='/images/projects/wirl-video-shopping.jpg' />
            </div>
          </SectionImage>
          <Tags>
            <div>Mobile</div>
          </Tags>
          <SectionDescription>
            <h2 className="text-2xl my-4">Wirl - Video Shopping</h2>
            <p className="my-4">
            Say goodbye to boring picture shopping! Traverse through all the shopping item videos to get a full review about the shopping listings.
            </p>
            <p className="my-4">
              Use your wallet to buy listing items. Invite your friends to recieve wallet rewards.
            </p>
            <MoreInfo href="/projects/wirl" color="blue">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </SectionDescription>
        </Section>
      </Row>
    </>
  )
}

export default Clients;